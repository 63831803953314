import VueRouter from 'vue-router'
import Vue from 'vue'
/* Layout */
Vue.use(VueRouter)

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch(err => err);
};

//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalReplace.call(this, location, onResolve, onReject);
    return originalReplace.call(this, location).catch(err => err);
};


import Layout from '@/layout'
import Allchildetail from '@/layout/allchildetail'

// meta:{
// 	headicon  0是返回图标 1是首页图标
//  headpath  点击头部返回或者首页图标按钮跳转地址
// }

const routes = [

    // {
    // 	path: '/',
    // 	component: Allchildetail,
    // 	redirect: '/home',
    // 	hidden: true,
    // 	children: [{
    // 		path: 'home',
    // 		component: () => import('@/views/home/index.vue'),
    // 		name: '首页',
    // 		meta: {
    // 			headtitle: '小市街道',
    // 			title: '首页',
    // 			headicon: 1,
    // 			headpath: '/wisconstr',
    // 		}
    // 	}]
    // },

    {
        path: '/villagedetail',
        component: Allchildetail,
        redirect: '/positions/villagedetail',
        hidden: true,
        children: [{
            path: '/positions/villagedetail',
            component: () => import('@/views/positions/children/villagedetail.vue'),
            name: '村社详细介绍',
            meta: {
                title: '村社详细介绍',
                headpath: '返回',
                headtitle: '返回',
            }
        }]
    },
    {
        path: '/politicalpage',
        component: Allchildetail,
        redirect: '/politica/politicalpage',
        hidden: true,
        children: [{
            path: '/politica/politicalpage',
            component: () => import('@/views/politica/children/politicalpage.vue'),
            name: '政法力量分布子',
            meta: {
                title: '政法力量分布子',
                headpath: '返回',
                headtitle: '返回',
            }
        }]
    },
    {
        path: '/gencywork',
        component: Allchildetail,
        redirect: '/emergency/gencywork',
        hidden: true,
        children: [{
            path: '/emergency/gencywork',
            component: () => import('@/views/emergency/children/gencywork.vue'),
            name: '临港应急工作介绍',
            meta: {
                title: '临港应急工作介绍',
                headpath: '返回',
                headtitle: '返回',
            }
        }]
    },
    {
        path: '/tabledetails',
        component: Allchildetail,
        redirect: '/traffic/tabledetails',
        hidden: true,
        children: [{
            path: '/traffic/tabledetails',
            component: () => import('@/views/traffic/children/tabledetails.vue'),
            name: '交通管理表格详情页',
            meta: {
                title: '交通管理表格详情页',
                headpath: '返回',
                headtitle: '返回',
            }
        }]
    },
    {
        path: '/spetabledetails',
        component: Allchildetail,
        redirect: '/special/spetabledetails',
        hidden: true,
        children: [{
            path: '/special/spetabledetails',
            component: () => import('@/views/special/children/spetabledetails.vue'),
            name: '特殊人员表格详情页',
            meta: {
                title: '特殊人员表格详情页',
                headtitle: '返回',
                headpath: '返回',
            }
        }]
    },
    // 公共安全二级页面开始
    {
        path: '/fishingyangtze',
        component: Allchildetail,
        redirect: '/security/fishingyangtze',
        hidden: true,
        children: [{
            path: '/security/fishingyangtze',
            component: () => import('@/views/security/children/fishingyangtze.vue'),
            name: '长江禁捕',
            meta: {
                title: '长江禁捕',
                headtitle: '返回',
                headpath: '返回',
            }
        }]
    }, {
        path: '/floodcontrol',
        component: Allchildetail,
        redirect: '/security/floodcontrol',
        hidden: true,
        children: [{
            path: '/security/floodcontrol',
            component: () => import('@/views/security/children/floodcontrol.vue'),
            name: '防汛管理',
            meta: {
                title: '防汛管理',
                headtitle: '返回',
                headpath: '返回',
            }
        }]
    }, {
        path: '/firemanagement',
        component: Allchildetail,
        redirect: '/security/firemanagement',
        hidden: true,
        children: [{
            path: '/security/firemanagement',
            component: () => import('@/views/security/children/firemanagement.vue'),
            name: '消防管理',
            meta: {
                title: '消防管理',
                headtitle: '返回',
                headpath: '返回',
            }
        }]
    }, {
        path: '/forestprevention',
        component: Allchildetail,
        redirect: '/security/forestprevention',
        hidden: true,
        children: [{
            path: '/security/forestprevention',
            component: () => import('@/views/security/children/forestprevention.vue'),
            name: '森林防火',
            meta: {
                title: '森林防火',
                headtitle: '返回',
                headpath: '返回',
            }
        }]
    }, {
        path: '/geologicalhazard',
        component: Allchildetail,
        redirect: '/security/geologicalhazard',
        hidden: true,
        children: [{
            path: '/security/geologicalhazard',
            component: () => import('@/views/security/children/geologicalhazard.vue'),
            name: '地质灾害',
            meta: {
                title: '地质灾害',
                headtitle: '返回',
                headpath: '返回',
            }
        }]
    },
    // 公共安全二级页面结束

    // 社会救助二级页面开始
    {
        path: '/retiredmilitary',
        component: Allchildetail,
        redirect: '/assistance/retiredmilitary',
        hidden: true,
        children: [{
            path: '/assistance/retiredmilitary',
            component: () => import('@/views/assistance/children/retiredmilitary.vue'),
            name: '退役军人服务站',
            meta: {
                title: '退役军人服务站',
                headtitle: '返回',
                headpath: '返回',
            }
        }]
    }, {
        path: '/migrantworkers',
        component: Allchildetail,
        redirect: '/assistance/migrantworkers',
        hidden: true,
        children: [{
            path: '/assistance/migrantworkers',
            component: () => import('@/views/assistance/children/migrantworkers.vue'),
            name: '农民工服务站',
            meta: {
                title: '农民工服务站',
                headtitle: '返回',
                headpath: '返回',
            }
        }]
    }, {
        path: '/assisservice',
        component: Allchildetail,
        redirect: '/assistance/assisservice',
        hidden: true,
        children: [{
            path: '/assistance/assisservice',
            component: () => import('@/views/assistance/children/assisservice.vue'),
            name: '社会救助服务站',
            meta: {
                title: '社会救助服务站',
                headtitle: '返回',
                headpath: '返回',
            }
        }]
    }, {
        path: '/peopleliving',
        component: Allchildetail,
        redirect: '/assistance/peopleliving',
        hidden: true,
        children: [{
            path: '/assistance/peopleliving',
            component: () => import('@/views/assistance/children/peopleliving.vue'),
            name: '社区独居老人服务站',
            meta: {
                title: '社区独居老人服务站',
                headtitle: '返回',
                headpath: '返回',
            }
        }]
    },
    {
        path: '/nursinghome',
        component: Allchildetail,
        redirect: '/assistance/nursinghome',
        hidden: true,
        children: [{
            path: '/assistance/nursinghome',
            component: () => import('@/views/assistance/children/nursinghome.vue'),
            name: '敬老院服务站',
            meta: {
                title: '敬老院服务站',
                headtitle: '返回',
                headpath: '返回',
            }
        }]
    },
    // 社会救助二级页面结束

    // 城市管理开始
    {
        path: '/citygemeteam',
        component: Allchildetail,
        redirect: '/urbanmana/citygemeteam',
        hidden: true,
        children: [{
            path: '/urbanmana/citygemeteam',
            component: () => import('@/views/urbanmana/children/citygemeteam.vue'),
            name: '城市管理队伍',
            meta: {
                title: '城市管理队伍',
                headtitle: '返回',
                headpath: '返回',
            }
        }]
    }, {
        path: '/smartepidemic',
        component: Allchildetail,
        redirect: '/urbanmana/smartepidemic',
        hidden: true,
        children: [{
            path: '/urbanmana/smartepidemic',
            component: () => import('@/views/urbanmana/children/smartepidemic.vue'),
            name: '智慧防疫专栏',
            meta: {
                title: '智慧防疫专栏',
                headtitle: '返回',
                headpath: '返回',
            }
        }]
    }, {
        path: '/securitycolumn',
        component: Allchildetail,
        redirect: '/urbanmana/securitycolumn',
        hidden: true,
        children: [{
            path: '/urbanmana/securitycolumn',
            component: () => import('@/views/urbanmana/children/securitycolumn.vue'),
            name: '社会治安重点管控单位专栏',
            meta: {
                title: '社会治安重点管控单位专栏',
                headtitle: '返回',
                headpath: '返回',
            }
        }]
    },
    // 城市管理结束



    {
        path: '/',
        component: Layout,
        redirect: '/wisconstr/index',
        children: [{
            path: '/wisconstr/index',
            component: () => import('@/views/wisconstr/index'),
            name: '智慧党建',
            meta: {
                title: '智慧党建',
            }
        }]
    },

    {
        path: '/special',
        component: Layout,
        redirect: '/special/index',
        children: [{
            path: 'index',
            component: () => import('@/views/special/index'),
            name: '特殊人员',
            meta: {
                title: '特殊人员',
            }
        }]
    },
    //politica/politicalpage?name=网格员&icontype=wgy&tabtitle=网格员&code=wgy
    {
        path: '/politica',
        component: Layout,
        redirect: '/politica/index',
        children: [{
            path: 'index',
            component: () => import('@/views/politica/index'),
            name: '网格员',
            meta: {
                title: '网格员',
            }
        }]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login/index'),
        hidden: true
    },
    {
        path: '/404',
        component: () => import('@/views/error/404'),
        hidden: true
    },
    // 404 page must be placed at the end !!!
    // {
    // 	path: '*',
    // 	redirect: '/404',
    // 	hidden: true
    // }
]

// 3. 创建 router 实例，然后传 `routes` 配置
// 你还可以传别的配置参数, 不过先这么简单着吧。
const router = new VueRouter({
    routes // (缩写) 相当于 routes: routes
})

router.beforeEach((to, from, next) => {
    let istoken = sessionStorage.getItem('token')
    if (to.name !== 'login' && !istoken) {
        next({ path: '/login' })
    } else {
        next()
    }
})

export default router
