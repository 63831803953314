<template>
  <div>
    <headmodule
      :navto="navto"
      :icon="routedata.meta.headicon"
      :title="routedata.meta.headtitle"
    ></headmodule>
    <div style="padding: 0 1rem 0 1rem">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import headmodule from "./components/Sidebar/headmodule.vue";
export default {
  components: {
    headmodule,
  },
  data() {
    return {
      routedata: {},
      navto: "",
    };
  },
  created() {
    this.routedata = this.$route;
    this.navto = this.$route.meta.headpath;
  },
  mounted() {},
  methods: {},
};
</script>
