import axios from 'axios'
import {
	Loading
} from 'element-ui';
import _ from 'lodash';
// loading对象
let loadingInstance;

// 请求合并只出现一次loading
// 当前正在请求的数量
let loadingRequestCount = 0;
// 显示loading的函数 并且记录请求次数 ++
const showLoading = (target) => {
	if (loadingRequestCount === 0) {
		loadingInstance = Loading.service({
			lock: true,
			text: '加载中...',
			background: "rgba(2, 0, 77, 0.6)",
			target: target
		});
	}
	loadingRequestCount++;
}

// 隐藏loading的函数，并且记录请求次数
const hideLoading = () => {
	if (loadingRequestCount <= 0) return;
	loadingRequestCount--;
	if (loadingRequestCount === 0) {
		toHideLoading();
	}
}

// 防抖：将 300ms 间隔内的关闭 loading 便合并为一次. 防止连续请求时, loading闪烁的问题。
var toHideLoading = _.debounce(() => {
	loadingInstance.close();
	loadingInstance = null;
}, 300);


axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded' //声明请求格式


// 创建 axios 对象
const instance = axios.create({
	// baseURL: 'lgapi/', // 根路径
	baseURL: process.env.VUE_APP_BASE_URL, // 根路径
	// baseURL: 'http://110.185.185.145:8083', // 根路径
	timeout: 16000, // 网络延时
})

// 添加请求拦截器 => 前端给后端的参数【还没到后端响应】
instance.interceptors.request.use(function (config) {
	// 在发送请求之前做些什么

	showLoading();

	return config;
}, function (error) {
	// 对请求错误做些什么
	setTimeout(() => {
		hideLoading()
	}, 200);

	return Promise.reject(error);
});

// 添加响应拦截器 => 后端给前端的数据【后端返回给前端的东西】
instance.interceptors.response.use(function (response) {
	// 对响应数据做点什么


	setTimeout(() => {
		hideLoading()
	}, 200);
	let resdata = response.data

	return resdata;
}, function (error) {
	// 对响应错误做点什么
	setTimeout(() => {
		hideLoading()
	}, 200);

	return Promise.reject(error);
});

// 最终返回的对象
export default instance
