<style lang="less">
.sidbox-style {
  width: 476px;
  padding: 0 0 0 16px;
  display: flex;
  flex-direction: column;
  .left-top-title-hydrograph {
    height: 160px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-top-title-text {
      .left-top-title-text-style {
        font-size: 32px;
        font-weight: bold;
      }
      .left-top-title-miaoshu-style {
        margin-top: 10px;
        .left-top-title-miaoshu-style-text {
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
  }
  .left-top-title-miaoshu-style-icon {
    display: inline-block;
    width: 60px;
    height: 25px;
    border-radius: 0.8125rem 0.8125rem 0.8125rem 0.8125rem;
    font-size: 14px;
    text-align: center;
    margin-left: 10px;
  }
  .bottom-list-style {
  }
  .bottom-list-style ul li {
    width: 460px;
    height: 54px;
    background: url("../../../assets/allimg/item-list-bg.png") no-repeat;
    background-size: 100%;
    margin-bottom: 14.4px;
    display: flex;
    align-items: center;
  }
  .bottom-list-style ul li:hover {
    background-image: url("../../../assets/allimg/sel-item-list-bg.png");
    cursor: pointer;
  }
  .sel_li_bg {
    background-image: url("../../../assets/allimg/sel-item-list-bg.png") !important;
  }
}

.youxiu {
  border: 0.125rem solid #35cd98;
  color: #23d496;
}
.lianghao {
  border: 0.125rem solid #2d9afe;
  color: #2d9afe;
}
.zhongdeng {
  border: 0.125rem solid #febe00;
  color: #febe00;
}

.selyouxiu {
  background: #35cd98;
  color: #fff;
}
.sellianghao {
  background: #2d9afe;
  color: #fff;
}
.selzhongdeng {
  background: #febe00;
  color: #fff;
}
#liquidFill {
  width: 100%;
  height: 100%;
}
</style>
<template>
  <div class="sidbox-style">
    <div class="left-top-title-hydrograph">
      <div class="left-top-title-text">
        <span class="left-top-title-text-style">智慧小市</span>
        <div class="left-top-title-miaoshu-style">
          <!-- <span class="left-top-title-miaoshu-style-text">综合评估</span>
          <span
            class="left-top-title-miaoshu-style-icon"
            :class="[
              metaresults == '良'
                ? 'sellianghao'
                : metaresults == '优'
                ? 'selyouxiu'
                : metaresults == '中'
                ? 'selzhongdeng'
                : '',
            ]"
            >{{ metaresults }}</span
          > -->
        </div>
      </div>
      <div class="" style="width: 120px; height: 120px">
        <!-- <dv-water-level-pond
          :config="config"
          style="width: 7.5rem; height: 7.5rem"
        /> -->

        <!-- 水球图 -->
        <!-- <div id="liquidFill"></div> -->
      </div>
    </div>
    <div class="bottom-list-style">
      <ul>
        <li
          v-for="(item, index) in routdata"
          :key="index"
          v-if="!item.hidden"
          :data-path="item.path"
          :data-name="item.children[0].name"
          :data-value="item.children[0].name"
          @click="
            routepushfun(
              item.children[0].name,
              item.path,
              index,
              item.children[0].meta.results,
              item.children[0].meta.number
            )
          "
          :class="seleclsname === item.children[0].name ? 'sel_li_bg' : ''"
        >
          <div style="display: inline-block; width: 90%; text-align: center;">
            <span>{{ item.children[0].meta.title }}</span>
          </div>
          <!-- <div style="display: inline-block; width: 30%">
            <span
              class="left-top-title-miaoshu-style-icon"
              :class="[
                item.children[0].meta.results == '优'
                  ? 'youxiu'
                  : item.children[0].meta.results == '良'
                  ? 'lianghao'
                  : item.children[0].meta.results == '中'
                  ? 'zhongdeng'
                  : '',
                item.children[0].meta.results == '优' &&
                seleclsname === item.children[0].name
                  ? 'selyouxiu'
                  : item.children[0].meta.results == '良' &&
                    seleclsname === item.children[0].name
                  ? 'sellianghao'
                  : item.children[0].meta.results == '中' &&
                    seleclsname === item.children[0].name
                  ? 'selzhongdeng'
                  : '',
              ]"
              >{{ item.children[0].meta.results || "无" }}</span
            >
          </div> -->
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { postgetMenu } from "@/api/menuList";
import * as echarts from "echarts";
import "echarts-liquidfill";

import router from "@/router";

export default {
  data() {
    return {
      config: {
        data: [55],
        shape: "round",
      },
      routdata: [],
      seleclsname: "",
      metaresults: "",
      gradenumber: "",
    };
  },
  created() {
    let _this = this;
    let routes = this.$router.options.routes;
    let routesall = [];
    routes.map((item) => {
      if (!item.hidden) {
        routesall.push(item);
      }
      return;
    });
    // postgetMenu().then((res) => {
      // let resdata = res.data;
      // let resdata = res.data;
      let resdata = [{"menu_id":1,"name":"智慧党建","describe":"智慧党建","area_id":4,"grade":"优","color":"#000000","number":100,"createtime":1667305445,"updatetime":1669602839},{"menu_id":7,"name":"特殊人员","describe":"","area_id":4,"grade":"优","color":"","number":100,"createtime":1668409978,"updatetime":1669602770}];

      routesall.map((item) => {
        item.children.map((chitem) => {
          resdata.map((resitem) => {
            if (chitem.name == resitem.name) {
              chitem.meta.results = resitem.grade;
              chitem.meta.number = resitem.number;
            }
          });
        });
      });

      _this.routdata = routesall;

      if (window.name != "0") {
        // 在首次进入页面时我们给window.name设置一个固定值(isRefresh)
        window.name = "0";
        _this.metaresults = routesall[0].children[0].meta.results;
        _this.gradenumber = routesall[0].children[0].meta.number;
        sessionStorage.setItem("grade", routesall[0].children[0].meta.results);
        sessionStorage.setItem(
          "gradenumber",
          routesall[0].children[0].meta.number
        );
      } else if (window.name == "0") {
        if (_this.$route.name == "智慧党建") {
          _this.metaresults = routesall[0].children[0].meta.results;
          _this.gradenumber = routesall[0].children[0].meta.number;
          sessionStorage.setItem(
            "grade",
            routesall[0].children[0].meta.results
          );
          sessionStorage.setItem(
            "gradenumber",
            routesall[0].children[0].meta.number
          );
        } else {
          _this.metaresults = sessionStorage.getItem("grade");
          _this.gradenumber = sessionStorage.getItem("gradenumber");
        }
      }

      _this.seleclsname = _this.$route.name;
      // 生成水球图
      // this.drawChart();
    // });
  },
  mounted() {},
  methods: {
    routepushfun(name, path, index, results, number) {
      this.seleclsname = name;
      this.metaresults = results;
      sessionStorage.setItem("grade", results);
      sessionStorage.setItem("gradenumber", number);
      this.$router.push(path);
      // this.drawChart();
    },

    drawChart() {
      var chartDom = document.getElementById("liquidFill");
      var myChart = echarts.init(chartDom);
      var option;
      option = {
        title: [
          {
            text: "评估分数",
            x: "center",
            y: "20%",
            textStyle: {
              fontSize: 14,
              color: "#fff",
            },
          },
          {
            text: sessionStorage.getItem("gradenumber"),
            x: "center",
            y: "40%",
            textStyle: {
              fontSize: 14,
              color: "#FEBE00",
            },
          },
        ],
        series: [
          {
            type: "liquidFill",
            radius: "100%",
            center: ["50%", "50%"],
            color: [
              {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#1EE7E7",
                  },
                  {
                    offset: 1,
                    color: "#1890FF",
                  },
                ],
                globalCoord: false,
              },
            ],
            data: [
              sessionStorage.getItem("gradenumber") / 100,
              sessionStorage.getItem("gradenumber") / 100,
            ], // data个数代表波浪数
            backgroundStyle: {
              borderWidth: 1,
              color: "rgba(117, 205, 255, 0.25)",
            },
            label: {
              normal: {
                show: false,
                textStyle: {
                  fontSize: 12,
                  fontWeight: "bold",
                  color: "#FEBE00",
                },
              },
            },
            outline: {
              borderDistance: 0,
              itemStyle: {
                borderWidth: 4,
                borderColor: "transparent",
              },
            },
          },
        ],
      };

      option && myChart.setOption(option);
    },
  },
};
</script>
