<style lang="less">
.headbg-style {
  background: url("../../../assets/allimg/head_bg.png") no-repeat;
  background-size: 100%;
  height: 5rem;
  margin: 0.75rem 0 1rem 0;
  display: flex;
  justify-content: space-between;
}
.title-cont-text {
  color: #d2fcfd;
  font-size: 2.25rem;
  line-height: 5rem;
}
.left-riht-tianqi-riqi {
  vertical-align: -webkit-baseline-middle;
  width: 20%;
  //   line-height: 8.125rem;
  text-align: center;
  align-items: flex-end;
  padding-bottom: 0.3125rem;
}

.mar-item {
  margin-left: 1.25rem;
}
.backnavto-icon {
  width: 1.25rem;
  height: 1.25rem;
  background-repeat: no-repeat;
  background-size: 100%;
}

.back-nav-btn-style:hover {
  cursor: pointer;
}

.back-nav-btn-style {
  align-items: center;
  justify-content: space-evenly;
  display: flex;
  width: 6.5rem;
  height: 2rem;
  border-radius: 0.25rem;
  background: #0c27a0;
  margin-left: 2.5rem;
  border: 1px solid #2d9afe;
}
</style>
<template>
  <div class="headbg-style">
    <div class="left-riht-tianqi-riqi clflex">
      <!-- <i class="iconfont icon-shijian"></i> -->
      <span class="mar-item">{{ yearmonthday }}</span>
      <span class="mar-item">{{ hourminute }}</span>
    </div>
    <div class="title-cont-text">小市街道基层治理平台</div>
    <div
      class="left-riht-tianqi-riqi clflex"
      style="justify-content: flex-end; padding-right: 17px"
    >
      <!-- <i class="iconfont icon-tianqi-qing"></i> -->
      <span class="mar-item">泸州</span>
      <span class="mar-item">11°</span>
      <span class="mar-item">阴天</span>
      <!-- <div class="back-nav-btn-style" @click="navtohome(navto)">
        <img
          class="backnavto-icon"
          :src="
            icon === 0
              ? require('../../../assets/allimg/backnav-icon.png')
              : require('../../../assets/allimg/wishome.png')
          "
        />
        <span> {{ title }} </span>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      yearmonthday: "",
      hourminute: "",
      iconimg: "",
    };
  },
  props: {
    navto: {
      type: String,
      default: "首页",
    },
    icon: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "首页",
    },
  },
  created() {},
  mounted() {
    this.gitthisdate();

    setInterval(() => {
      this.gitthisdate();
    }, 6000);
  },
  methods: {
    gitthisdate() {
      let newdata = new Date();
      //年
      let year = new Date().getFullYear();
      //月份是从0月开始获取的，所以要+1;
      let month = new Date().getMonth() + 1;
      //日
      let day = new Date().getDate();
      //时
      let hour = new Date().getHours();
      //分
      let minute = new Date().getMinutes();
      //秒
      let second = new Date().getSeconds();
      this.yearmonthday = year + "-" + month + "-" + day;
      let zminute = minute < 10 ? "0" + minute : minute; //判断时间的分   小于10 拼接上0
      this.hourminute = hour + ":" + zminute;
    },

    navtohome(e) {
      if (e == "返回") {
        this.$router.go(-1);
      } else if (e == "首页") {
        this.$router.push("/");
        // window.location.href = "http://110.185.185.145:8084/#/home";
      } else {
        this.$router.push('/wisconstr/index');
        // window.location.href = "http://hjlg.quanyu.space/#/wisconstr/index";
      }
    },
  },
};
</script>
