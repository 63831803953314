<template>
  <div style="height: 100%">
    <HeadModule />

    <!-- <app-main /> -->

    <div class="bottom-minstyle">
      <sidebar />
      <app-main />
    </div>
  </div>
</template>

<script>
import { AppMain, Sidebar, HeadModule } from "./components";
export default {
  name: "Layout",
  components: {
    HeadModule,
    AppMain,
    Sidebar,
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less">
.bottom-minstyle {
  display: flex;
}
</style>
