import Vue from 'vue'
import App from './App.vue'
import dataV from '@jiaminghi/data-view'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import router from './router/index';
// 阿里图标库
import "@/icons/aliiconfont/iconfont.css";
import Vue2OrgTree from 'vue2-org-tree'
import 'vue2-org-tree/dist/style.css'

import VueAMap from '@vuemap/vue-amap';
import '@vuemap/vue-amap/dist/style.css'

Vue.prototype.$baseURL = 'http://ht.hjlgstreet.com/'

// 第一个是videoJs的样式，后一个是vue-video-player的样式，因为考虑到我其他业务组件可能也会用到视频播放，所以就放在了main.js内
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
import VideoPlayer from 'vue-video-player'

import 'videojs-contrib-hls'

Vue.use(VueAMap);

VueAMap.initAMapApiLoader({
  key: '7a19317479ec28f69447e9a0700ebfcc',
});

Vue.use(VideoPlayer);

import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

import ElTableInfiniteScroll from "el-table-infinite-scroll";
Vue.directive("el-table-infinite-scroll", ElTableInfiniteScroll);
Vue.use(Vue2OrgTree)
Vue.use(VueRouter);
Vue.use(Vuex)
Vue.use(dataV)
Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
