var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidbox-style"},[_vm._m(0),_c('div',{staticClass:"bottom-list-style"},[_c('ul',_vm._l((_vm.routdata),function(item,index){return (!item.hidden)?_c('li',{key:index,class:_vm.seleclsname === item.children[0].name ? 'sel_li_bg' : '',attrs:{"data-path":item.path,"data-name":item.children[0].name,"data-value":item.children[0].name},on:{"click":function($event){return _vm.routepushfun(
            item.children[0].name,
            item.path,
            index,
            item.children[0].meta.results,
            item.children[0].meta.number
          )}}},[_c('div',{staticStyle:{"display":"inline-block","width":"90%","text-align":"center"}},[_c('span',[_vm._v(_vm._s(item.children[0].meta.title))])])]):_vm._e()}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left-top-title-hydrograph"},[_c('div',{staticClass:"left-top-title-text"},[_c('span',{staticClass:"left-top-title-text-style"},[_vm._v("智慧小市")]),_c('div',{staticClass:"left-top-title-miaoshu-style"})]),_c('div',{staticStyle:{"width":"120px","height":"120px"}})])
}]

export { render, staticRenderFns }