<template>
  <div id="app">
    <ScaleBox :width="1920" :height="1088" bgc="transparent" :delay="0">
        <router-view></router-view>
    </ScaleBox>
  </div>
</template>

<script>
import ScaleBox from "vue2-scale-box";

export default {
  components: {
    ScaleBox,
  },
  name: "App",
};
</script>

<style>
@import url("./styles/common.css");

#app {
  background-color: #02004d;
  height: 100vh;
  width: 100vw;
}
</style>
